<script setup lang="ts">
interface IProps {
    blok: PageZipCodeFormBlokInterface;
    inputId: string;
    dark?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
    dark: false,
});

const route = useRoute();
const runtimeConfig = useRuntimeConfig();

const queryZipCode = route.query.zip_code as unknown as string;
const zipCode = ref(queryZipCode ?? '');

const submitUrl = computed(() => processDomainTokensInUrl(props.blok.submitUrl.url));

const initializeZipCode = async () => {
    const queryZipCode = route.query.zip_code;
    if (queryZipCode) {
        // Use zip code from query parameter if it exists
        zipCode.value = queryZipCode.toString();
    } else {
        try {
            const apiKey = runtimeConfig.public.GMAP_API_KEY_CMS;
            if (apiKey) {
                await loadGoogleMapsAPI(apiKey as any, 'places').then(async () => {
                    zipCode.value = await getLocation(runtimeConfig.public.IPAPI_KEY || '');
                });
            }
        } catch (e) {
            console.error((e as Error).message);
        }
    }
};

onMounted(() => {
    initializeZipCode();
});
</script>

<template>
    <es-zip-code-form
        v-editable="blok"
        :context-message="blok.contextMessage"
        :constrained="false"
        :dark="dark"
        :field-name="blok.fieldName || 'zip_code'"
        :input-id="blok._uid || ''"
        :new-tab="blok.submitUrl.target === '_blank'"
        :placeholder="blok.label"
        :show-privacy-section="!!(blok.privacyExplanation && blok.privacyPolicyLinkText)"
        privacy-policy-link="https://www.energysage.com/privacy-policy"
        :privacy-policy-new-tab="true"
        stack-until="lg"
        :selected-product="blok.productSelection"
        :url="submitUrl"
        :zip-code-value="zipCode">
        <template #buttonText>
            {{ blok.submitButtonText }}
        </template>
        <template #errorMessage>
            {{ blok.errorDescription }}
        </template>
        <template #privacyExplanation>
            <!-- needed to ensure a space between the explanation and link -->
            {{ `${blok.privacyExplanation} ` }}
        </template>
        <template #privacyPolicyLinkText>
            {{ blok.privacyPolicyLinkText }}
        </template>
    </es-zip-code-form>
</template>
